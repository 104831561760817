<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.barcode") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.element") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.origin") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.conformity_code") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.user_manual_1") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.goods_name") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.goods_description") }}
            </th>
            <th role="columnheader" class="vertical-align-middle">
              {{ $t("labels.size") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`c_${index}`">
            <td class="vertical-align-middle">
              {{ item.customer_goods_barcode }}
            </td>
            <td class="vertical-align-middle error--text">
              {{ item.element }}
            </td>
            <td class="vertical-align-middle error--text">
              {{ item.origin }}
            </td>
            <td class="vertical-align-middle error--text">
              {{ item.conformity_code }}
            </td>
            <td class="vertical-align-middle error--text">
              {{ item.user_manual }}
            </td>
            <td class="vertical-align-middle">
              {{ item.name }}
            </td>
            <td class="vertical-align-middle">
              {{ item.description }}
            </td>
            <td class="vertical-align-middle">
              {{ item.size }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="text-right mt-3">
      <v-btn small color="warning" @click="downloadExcel" class="mr-2">
        <v-icon>mdi-download</v-icon> {{ $t("labels.goods") }}
      </v-btn>
      <v-btn
        small
        color="info"
        @click="$refs.inputUploadFile.click()"
        class="mr-2"
      >
        <v-icon>mdi-upload</v-icon> {{ $t("labels.upload") }}
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        accept=".xlsx"
        @change="onInputFileChange"
        class="d-none"
      />

      <v-btn
        small
        color="success"
        @click="updateGoodsElement"
        :disabled="isDisabledBtnSubmit"
        ><v-icon>mdi-check</v-icon> {{ $t("labels.update") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "UpdateElementExcel",
  data: () => ({
    isLoading: false,
    items: [],
    errRows: [],
    categories: [],
  }),
  computed: {
    isDisabledBtnSubmit() {
      return this.items.length === 0 || this.errRows.length > 0;
    },
  },
  mounted() {},
  methods: {
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;

      const mappingFields = {
        Barcode: "customer_goods_barcode",
        "Thành phầIngredient": "element",
        "Xuất xứOrigin": "origin",
        "Mã hồ sơRecord ID": "conformity_code",
        "Hướng dẫn sử dụngInstruction": "user_manual",
        "TênProduct Name": "name",
        "Đặc tảDescription": "description",
        Size: "size",
      };

      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = utils.sheet_to_json(worksheet);

          const items = [...values].map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              const mapkey = i.replace(/\r?\n|\r/g, "");
              item[mappingFields[mapkey]] = `${v[i]}`.trim();
            });
            return item;
          });
          this.validateGoodsCategory(items);
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    validateGoodsCategory(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }
      if (items.length > 1000) {
        this.$vToastify.error(
          this.$t("messages.update_goods_element_max_1000_rows")
        );
        return false;
      }

      const emptyCategory = items
        .filter(
          (item) => !item.element || !item.origin || !item.conformity_code
        )
        .map((item) => item.customer_goods_barcode);
      if (emptyCategory && emptyCategory.length > 0) {
        this.$vToastify.error(
          this.$t("messages.empty_element_barcodes", {
            barcodes: emptyCategory.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n == i.customer_goods_barcode
        );
        if (!nd) {
          noDuplicateBarcodes.push(i.customer_goods_barcode);
        } else {
          duplicateBarcodes.push(i.customer_goods_barcode);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      this.items = [...items];
    },

    async updateGoodsElement() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-update-element-list", {
          items: this.items,
        });
        this.items = [];
        this.$vToastify.success(this.$t("messages.update_success"));
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-hang-hoa-thanh-phan.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-export-for-element",
          {},
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
